import { useState } from "react";
import { Document, Page } from "react-pdf";
import { Carousel } from "react-responsive-carousel";
import "./solutions.css";

import cd4 from "../../media/BIM.png";
import cd2 from "../../media/cifrovoj-dvojnik.png";
import cd3 from "../../media/gruz.jfif";

import da1 from "../../media/DA1.jpg";
import da2 from "../../media/DA2.jpg";
import da3 from "../../media/DA3.jpg";
import da4 from "../../media/DA4.jpg";
import cf1 from "../../media/PAK2.jpg";

import ce1 from "../../media/AI.png";

export default function Solutions() {
  const [curPage, setPage] = useState(0);

  const buttons = ["Телемедицина", "Цифровые двойники", "Аналитика"].map(
    (value, index) => (
      <div
        onClick={() => {
          setPage(index);
        }}
        className={`solutions-button ${curPage === index && "bActive"}`}
        id={`button-${index}`}
      >
        {value}
      </div>
    )
  );

  const fields = [
    [
      {
        image: cf1,
        header:
          "Программно-аппаратный комплекс дистанционного мониторинга здоровья",
        subtext:
          "Решение направлено на  круглосуточный мониторинг с медицинской точностью наиболее важных показателей состояния здоровья человека c параллельным выводом их на мобильное устройство.",
        advantages: [
          {
            header: null,
            list: [
              "Анализ частоты дыхания и патологии дыхания",
              "Понятная интерпретация получаемых данных не мед. работником",
              "Автоматическое выявление снижение уровня sPO2",
              "Позволяет просто и быстро снимать ЭКГ и ЧСС",
              "Автоматическое выявление сердечно-сосудистых и респираторных патологий",
              "Фиксация типовых состояний организма, предшествующих угрожающим жизни состояниям",
            ],
          },
        ],
      },
      {
        images: [
          [da1, da2],
          [da3, da4],
        ],
        header: "Единая платформа дистанционного контроля здоровья",
        subheader: '"Цифровой аватар"',
        subtext:
          "Модульная платформа, предназначенная для организации единой̆ среды по сбору медицинских данных из различных источников информации и формированию объективной̆ картины по состоянию здоровья сотрудников. Входящая в состав платформы технология искусственного интеллекта, позволяет интерпретировать данные и помогает принимать прозрачные управленческие и медицинские решения.",
        advantages: [
          {
            header: "Платформа предназначена для",
            list: [
              "Круглосуточного дистанционного контроля состояния здоровья сотрудников",
              "Обеспечения продления профессионального долголетия сотрудников",
              "Формирования группы рисков сотрудников",
              "Обеспечения оптимального планирования графиков дежурных смен с учетом регуляции сна, отдыха и восстановления работников",
              "Обеспечения персонифицированного подхода к формированию нормы физиологических параметров",
              "Обеспечения процессов проведения Профосмотров сотрудников",
            ],
          },
          {
            header: "Документы",
            list: [
              "Руководство пользователя",
              "Руководство администратора",
              "Презентация",
            ],
            links: [
              "/documents/digital_avatar_1.pdf",
              "/documents/digital_avatar_2.pdf",
              "/documents/digital_avatar_presentation.pdf",
            ],
          },
          {
            header:
              "Стоимость программного обеспечения рассчитывается индивидуально",
            list: [
              "Для рассчета стоимости свяжитесь с нашими специалистами: +7 (985) 229-47-24",
            ],
          },
        ],
      },
    ],
    [
      {
        image: cd2,
        header: "Цифровой двойник завода",
        subtext:
          "Решение направленно на мониторинг и диспетчеризацию работы производственного оборудования и транспорта, с последующим повышением эффективности использования и снижением себестоимости готовой продукции",
        advantages: [
          {
            header: null,
            list: [
              "Обеспечение автоматизации внутренних бизнес-процессов транспортного и производственных цехов",
              "Аналитические отчеты в различных временных срезах по работе отдельных производственных участков",
              "Предиктивная аналитика по техническому обслуживанию и ремонта",
              "Моделирование бизнес-процессов",
            ],
          },
        ],
      },
      {
        image: cd3,
        header: "Платформа имитационного моделирования регламентных процессов",
        subtext:
          "Решение направленно на моделирование и мониторинг регламентных процессов применения специальной техники для уборки территории, с последующим повышением эффективности  ее применения при различных условиях",
        advantages: [
          {
            header: null,
            list: [
              "Моделирование и расчет показателей применения различных типов техники",
              "Построение оптимальных траекторий движения ТС",
              "Оценка ТТХ применяемых ТС",
            ],
          },
        ],
      },
      {
        image: cd4,
        header: "Цифровой двойник стройки",
        subtext:
          "Решение предназначено для автоматизации процессов мониторинга, управления и проектирования строительных объектов, с целью формирования единой среды по управлению полным жизненным циклом зданий и их инженерных систем на всех этапах (концепция, проектирование, строительство, эксплуатация, реконструкция, вывод из эксплуатации)",
        advantages: [
          {
            header: null,
            list: [
              "Автоматический пересчёт объёмов работ",
              "Учёт выполненных работ",
              "Автоматизация оформления исполнительной документации",
              "Цифровое ведение журналов работ",
              "Упрощение электронного документооборота",
              "Формирование предписаний на этапе строительства и контроль их выполнений",
            ],
          },
        ],
      },
    ],
    [
      {
        header:
          "Программный комплекс поддержки решений на основе цифровых моделей для задач материально-технического обеспечения в системе послепродажного обслуживания",
        subtext:
          "Программный комплекс предназначен для автоматизации процесса послепродажного обслуживания сложных технических изделий. " +
          "Программа содержит модели обнаружения и предупреждения возникновения отказов при эксплуатации изделий, позволяя анализировать поведение сложного технического объекта для прогнозирования его состояния. " +
          "На основе данных моделей вычисляется эффективность функционирования системы обслуживания и ремонта. " +
          "Для выработки рекомендаций программный комплекс включает в себя компонент системы поддержки решений, позволяющий путем направленного перебора вариантов выдавать рекомендации по управлению процессами послепродажного обслуживания и ремонта по состоянию. " +
          "Для передачи данных в модели используется параметрический граф, содержащий как наборы параметров, так и возможные их комбинации. " +
          "Путь в параметрическом графе однозначно определяет необходимый набор параметров и управленческих решений для осуществления имитационных экспериментов.",
        advantages: [
          {
            header: "Документы",
            list: [
              "Инструкция по скачиванию и установки",
              "Описание процессов Жизненного цикла",
              "Руководство оператора",
              //"Исходный код",
            ],
            links: [
              "/documents/analytics/Инструкция_по_скачиванию_и_установки.docx",
              "/documents/analytics/Описание_процессов_Жизненного_цикла.docx",
              "/documents/analytics/Руководство_оператора.docx",
              //"/documents/analytics/57486f5b5544814e799963b583b0e832719e34b62b97f45d75cd3d00642e49c2/0003c3d863b610d2e43322ee2f744f9741ea790b40da964ac902a2bbd1fc2cad/Code.zip",
            ],
          },
          {
            header:
              "Стоимость программного обеспечения рассчитывается индивидуально",
            list: [
              "Для рассчета стоимости свяжитесь с нашими специалистами: +7 (985) 229-47-24",
            ],
          },
        ],
      },
      {
        image: ce1,
        header: "Облачная Платформа Интеллектуальной Обработки Данных",
        subtext:
          "Платформа предназначена для облачного хранения больших массивов данных и интеллектуальной обработки этих данных в автоматическом режиме. Для решения указанных задач применяются современные технологии искусственного интеллекта: машинное обучение, методы комбинаторной оптимизации и набор предобученных моделей искусственного интеллекта для различного рода расчетных задач. Сервис позволяет решать задачи в области медицины, строительства, транспорта и логистики, управления проектами",
      },
    ],
  ];

  const docsList = [
    "docs/Заключение ФМБА №119.pdf",
    "docs/Заключение ООО Нобилис.pdf",
    "docs/Заключение ООО ИЦ АСК.pdf",
    "docs/Заключение ООО Вита Мед.pdf",
    "docs/Заключение аналит. центр.pdf",
  ];

  const [activeTab, setActiveTab] = useState(0);
  const updateActiveTab = (index) => {
    if (index === activeTab) {
      return;
    }

    setActiveTab(index);
  };

  const extraElements = [
    <div className="docs-carousel">
      <h2 className="solutions-header">
        Сотрудничество с организациями и медицинскими учреждениями
      </h2>
      <Carousel
        infiniteLoop
        centerMode
        centerSlidePercentage={35}
        selectedItem={activeTab}
        onChange={updateActiveTab}
        statusFormatter={(current, total) => `${current} из ${total}`}
      >
        {docsList.map((d, i) => (
          <a href={d} key={i}>
            <Document file={d}>
              <Page className="doc-page" height={600} pageNumber={1} />
            </Document>
          </a>
        ))}
      </Carousel>
    </div>,
    <></>,
    <></>,
  ];

  return (
    <div className="solutions">
      <h1 className="solutions-header">РЕШЕНИЯ</h1>
      <div className="solutions-buttons">{buttons}</div>
      <div className="solutions-previews">
        {fields[curPage].map((object, index) => (
          <div className="field">
            {index !== 0 && <div className="field-separator"></div>}
            <div className="content">
              {object.image && (
                <img className="field-image" src={object.image} alt="img" />
              )}
              {object.images && (
                <div className="field-image-container">
                  {object.images.map((imageRow, index) => (
                    <div className="field-image-row" key={index}>
                      {imageRow.map((image, imindex) => (
                        <img
                          className="field-image"
                          src={image}
                          key={imindex}
                          alt="img"
                          style={{
                            width: `calc(50% - (${
                              (imageRow.length - 1) / imageRow.length
                            }) * 0.5rem)`,
                          }}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              )}
              <div className="field-text">
                <h2 className="field-header">{object.header}</h2>
                {object.subheader && <h4>{object.subheader}</h4>}
                <p className="field-subtext">{object.subtext}</p>
                {object.advantages &&
                  object.advantages.map((av) => (
                    <div className={av.class}>
                      {av.header ? <h3>{av.header}</h3> : ""}
                      <ul>
                        {av.list.map((text, tindex) => (
                          <li>
                            {!!av.links && !!av.links[tindex] ? (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={av.links[tindex]}
                              >
                                {text}
                              </a>
                            ) : (
                              text
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
        {extraElements[curPage]}
      </div>
    </div>
  );
}
