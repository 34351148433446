import "./partners.css";

import medicaLogo from "../../media/partners/medica.png";
import nicitLogo from "../../media/partners/nicit.png";
import pochtaLogo from "../../media/partners/pochta_rossii.png";
import rostelecomLogo from "../../media/partners/rostelecom.png";
import voentelecomLogo from "../../media/partners/voentelekom.png";
import westernLogo from "../../media/partners/western_petrolium.png";
import p1Logo from "../../media/partners/Coat_of_Arms_of_Tula_oblast.png";
import p2Logo from "../../media/partners/header__moscow-emblem.png";

export default function Partners() {
  return (
    <div className="partners">
      <h1 className="partners-header">ЗАКАЗЧИКИ</h1>
      <div className="partners-logos">
        <img className="partner-logo" src={voentelecomLogo} alt="vLogo" />
        <img className="partner-logo" src={medicaLogo} alt="mLogo" />
        <img className="partner-logo" src={westernLogo} alt="wLogo" />
        <img className="partner-logo" src={pochtaLogo} alt="pLogo" />
        <img id="nLogo" className="partner-logo" src={nicitLogo} alt="nLogo" />
        <img className="partner-logo" src={rostelecomLogo} alt="rtLogo" />
        <img id="p2Logo" className="partner-logo" src={p2Logo} alt="mskLogo" />
        <img id="p1Logo" className="partner-logo" src={p1Logo} alt="tulaLogo" />
      </div>
    </div>
  );
}
