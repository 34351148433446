import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./textSlider.css";

class TextSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      curPage: 0,
    };
  }

  text = [
    "Разработки Компании защищены свидетельствами об официальной регистрации программ для ЭВМ, выданными Российским агентством по патентам и товарным знакам.",
    "В своей деятельности Компания использует наработанные за несколько лет технологические подходы, позволяющие быстро и эффективно разрабатывать, и внедрять прикладные программные системы информационного типа, рассчитанные на эксплуатацию в больших организациях.",
    "Контроль качества работы и ее результатов проводится в соответствии с действующей в Компании системой менеджмента качества выполнения работ при проектировании, разработке, внедрении программ, программных комплексов, автоматизированных систем и их модификации, адаптации, развитии, техническом сопровождении согласно ISO 9000:2000.",
  ];

  updateCurrentSlide = (index) => {
    const { curPage } = this.state;

    if (curPage !== index) {
      this.setState({
        curPage: index,
      });
    }
  };

  render() {
    return (
      <div className="textSlider">
        <Carousel
          className="text-carousel"
          selectedItem={this.state.curPage}
          onChange={this.updateCurrentSlide}
          interval={10000}
          dynamicHeight={true}
          infiniteLoop={true}
          stopOnHover={false}
          autoPlay={true}
          showThumbs={false}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
        >
          <div>
            <p className="s-text">
              Разработки Компании защищены свидетельствами об официальной
              регистрации программ для ЭВМ, выданными Российским агентством по
              патентам и товарным знакам.
              <br />
              <br />
            </p>
          </div>
          <div>
            <p className="s-text">
              В своей деятельности Компания использует наработанные за несколько
              лет технологические подходы, позволяющие быстро и эффективно
              разрабатывать, и внедрять прикладные программные системы
              информационного типа, рассчитанные на эксплуатацию в больших
              организациях.
              <br />
              <br />
            </p>
          </div>
          <div>
            <p className="s-text">
              Контроль качества работы и ее результатов проводится в
              соответствии с действующей в Компании системой менеджмента
              качества выполнения работ при проектировании, разработке,
              внедрении программ, программных комплексов, автоматизированных
              систем и их модификации, адаптации, развитии, техническом
              сопровождении согласно ISO 9000:2000.
              <br />
              <br />
            </p>
          </div>
        </Carousel>
        <div className="buttonGroup">
          {[0, 1, 2].map((_, index) => (
            <div
              className={`textButton ${
                this.state.curPage === index && "bActive"
              }`}
              key={index}
              onClick={() => {
                this.setState({ curPage: index });
              }}
            ></div>
          ))}
        </div>
      </div>
    );
  }
}

export default TextSlider;
