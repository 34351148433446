import "./preview.css";
import bgImage from "../../media/preview-bg.png";
import pImage from "../../media/preview-phone.png";
import fImage1 from "../../media/Shape 1.png";
import fImage2 from "../../media/Shape 3.png";
import fImage3 from "../../media/Shape 4.png";
import fImage4 from "../../media/Shape 5.png";

export default function Preview() {
  return (
    <div style={{ backgroundImage: `url(${bgImage})` }} className="preview">
      <div className="preview-items">
        <img alt="phone" src={pImage} className="preview-image" />
        <div className="preview-features-list">
          <div className="preview-feature">
            <img src={fImage1} alt="f1" className="preview-feature-image" />
            <p className="preview-feature-text">РАЗРАБОТКА И ВНЕДРЕНИЕ</p>
          </div>
          <div className="preview-feature">
            <img src={fImage2} alt="f2" className="preview-feature-image" />
            <p className="preview-feature-text">
              АВТОМАТИЗАЦИЯ БИЗНЕС-ПРОЦЕССОВ
            </p>
          </div>
          <div className="preview-feature">
            <img src={fImage3} alt="f3" className="preview-feature-image" />
            <p className="preview-feature-text">
              СОПРОВОЖДЕНИE ИТ-ИНФРАСТРУКТУРЫ
            </p>
          </div>
          <div className="preview-feature">
            <img src={fImage4} alt="f4" className="preview-feature-image" />
            <p className="preview-feature-text">
              ВНЕДРЕНИЕ СРЕДСТВ ЗАЩИТЫ ИНФОРМАЦИИ
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
