import "./header.css";
import Logo from "../../media/logo.png";

export default function Header() {
  return (
    <div className="header">
      <img className="main-logo" src={Logo} alt="mainLogo" />
    </div>
  );
}
