import "./contacts.css";
import { Map, Placemark, YMaps } from "react-yandex-maps";

export default function Contacts() {
  return (
    <YMaps>
      <div className="contacts">
        <Map
          className="contacts-map"
          defaultState={{ center: [55.740629, 37.617168], zoom: 15 }}
          width="auto"
          height="auto"
        >
          <Placemark geometry={[55.740629, 37.617168]} />
        </Map>
        <div className="contacts-text">
          <h4>Праймсофт</h4>
          <p>119180, г. Москва, ул. Большая Полянка, д. 7/10, стр. 1</p>
          <p>Тел.: +7(985)229-47-24</p>
          <p>E-mail: info@primesoftpro.ru</p>
        </div>
      </div>
    </YMaps>
  );
}
