import "./App.css";
import Preview from "./components/preview/preview";
import Header from "./components/header/header";
import TextSlider from "./components/textSlider/textSlider";
import Solutions from "./components/solutions/solutions";
import Partners from "./components/partners/partners";
import Contacts from "./components/contacts/contacts";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  return (
    <div className="App">
      <Header />
      <Preview />
      <TextSlider />
      <Solutions />
      <Partners />
      <Contacts />
      <div className="footer">2013-2022 Primesoft. Все права защищены</div>
    </div>
  );
}

export default App;

